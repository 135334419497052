import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Theme: "light", // theme can be light or dark
    LayoutType: "full-sidebar", // this can be full-sidebar, mini-sidebar
    Sidebar_drawer: false,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    navbarColor: "primary",
    logoColor: "white",
    setHorizontalLayout: false, // Horizontal layout
    Direction: "ltr", // possible value  rtl / ltr
    // Calendar Events
    calendarEvents: [
      {
        title: "test",
        allDay: true,
        start: moment(),
        end: moment().add(1, "d"),
      },
    ],
    authentication: {user:{authinfo:{name:''}}, user_rule:''},
    dataTable: [],
    serviceTags: [],
    vehicleSelect: [],
    typeVehicleTags: [],
    tagsTags: ['tag1', 'tag2', 'tag3'],
    vectorsSelect: [],
    patientsSelect: [],
    clinicsSelect: [],
    patientSelected: 0
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.authentication = payload
      if (payload.authorisation !== undefined) localStorage.setItem('saluber_token', payload.authorisation.token)
    },
    SET_THEME(state, payload) {
      state.Theme = payload;
    },
    SET_LAYOUT_TYPE(state, payload) {
      state.LayoutType = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LOGO_COLOR(state, payload) {
      state.logoColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_DIRECTION(state, payload) {
      state.Direction = payload;
    },
    // Add Calendar event
    ADD_CALENDAR_EVENT(state, event) {
      const newEvent = Object.assign({}, event);
      state.calendarEvents.push(newEvent);
    },
    SET_DATA_TABLE(state, event) {
      state.dataTable = event
    },
    SET_SERVICE_TAGS(state, event) {
      state.serviceTags = []
      event.forEach(function (i) {
        state.serviceTags.push(i.id+' - '+i.description)
      })
    },
    SET_SERVICE_SELECT(state, event) {
      state.serviceTags = []
      event.forEach(function (i) {
        state.serviceTags.push({value:i.id, text: i.description})
      })
    },
    SET_VEHICLE_SELECT(state, event) {
      state.vehicleSelect = []
      event.forEach(function (i) {
        state.vehicleSelect.push({value:i.id, text: i.vehicle_number})
      })
    },
    SET_VEHICLE_TAGS(state, event) {
      state.typeVehicleTags = []
      event.forEach(function (i) {
        state.typeVehicleTags.push(i.id+' - '+i.description)
      })
    },
    SET_VECTOR_SELECT(state, event) {
      state.vectorsSelect = []
      event.forEach(function (i) {
        state.vectorsSelect.push({value:i.id, text: i.authinfo.name})
      })
    },
    SET_PATIENT_SELECT(state, event) {
      state.patientsSelect = []
      event.forEach(function (i) {
        state.patientsSelect.push({value:i.id, text: i.name})
      })
    },
    SET_CLINIC_SELECT(state, event) {
      state.clinicsSelect = []
      event.forEach(function (i) {
        state.clinicsSelect.push({value:i.id, text: i.company_name})
      })
    }
  },
  actions: {
    setLayoutType({ commit }, width) {
      commit("SET_LAYOUT_TYPE", width);
    },
    //Calendar Action
    addCalendarEvent({ commit }, event) {
      commit("ADD_CALENDAR_EVENT", event);
    },
    //Set auth user
    setAuthentication({ commit }, event) {
      commit("SET_TOKEN", event);
    },

    setDataTable({ commit }, event) {
      commit("SET_DATA_TABLE", event);
    },
    setTypeServiceTag({ commit }, event) {
      commit("SET_SERVICE_TAGS", event);
    },
    setVehiclesSelect({ commit }, event) {
      commit("SET_VEHICLE_SELECT", event);
    },
    setTypeServiceSelect({ commit }, event) {
      commit("SET_SERVICE_SELECT", event);
    },
    setTypeVehicleTag({ commit }, event) {
      commit("SET_VEHICLE_TAGS", event);
    },
    setVectorSelect({ commit }, event) {
      commit("SET_VECTOR_SELECT", event);
    },
    setPatientSelect({ commit }, event) {
      commit("SET_PATIENT_SELECT", event);
    },
    setClinicSelect({ commit }, event) {
      commit("SET_CLINIC_SELECT", event);
    },
  },
  getters: {},
});
