import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
// i18n
import i18n from './i18n/i18n.js'
import "vue-hljs/dist/style.css";
Vue.use(VueFeather);

Vue.config.productionTip = false;

import vueHljs from "vue-hljs";
import hljs from "highlight.js";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem(process.env.VUE_APP_TOKEN_SALUBER);
Vue.use(VueAxios, axios)

import Moment from "vue-moment"
const moment = require("moment")
require('moment/locale/it')
Vue.use(Moment, {moment});

import VueGoogleMaps from "@fawmi/vue-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAPS_TOKEN,
        libraries: ['visualization', 'places'],
        language: 'it',
    },
})
//use
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
new Vue({ store, router,i18n, render: (h) => h(App) }).$mount("#app");
